import crypto from 'crypto';
import actions from './actions';

const tid = function generateTemporaryId() {
  const now = Date.now().toString() + Math.random();
  const tid = crypto
    .createHash('md5')
    .update(now)
    .digest('hex')
    .toString();
  return tid;
};

const toGrid = function roundToGridSize(value, size = 0) {
  if (size === 0) {
    size = grid_size;
  }
  return Math.round(value / size) * size;
};

const grid_size = 20;

class Block {
  constructor(type, action_type, { left, top, width, height }, action_id = 0) {
    this.type = type; // Форма
    this.exists = action_id != 0;
    this.action_id = this.exists ? action_id : tid();
    this.action_type = action_type; // Цвет
    this.id = `${type}-${this.action_id}`;
    this.title = '';
    this.left = left;
    this.top = top;
    this.width = width;
    this.height = height;
    this.slots = [0, 0, 0, 0];
    this.selected = false;
  }
}

class Action extends Block {
  constructor(action_type, { left, top, width, height }, action_id = 0) {
    super('act', action_type, {
      left: toGrid(left),
      top: toGrid(top),
      width: toGrid(width),
      height: toGrid(height),
    }, action_id);
    this.gateway = null;
    this.errors = false;
    this.hasJumps = false;
    this.unloadId = null;
  }

  makeGateway(constraints) {
    if (!this.gateway) {
      this.gateway = new Gateway(this.action_type, constraints, this.action_id);
    }
    return this.gateway;
  }
}

class Gateway extends Block {
  constructor(action_type, { left, top, width, height }, action_id = 0) {
    super('gateway', action_type, {
      left: toGrid(left, grid_size / 2),
      top: toGrid(top, grid_size / 2),
      width: toGrid(width),
      height: toGrid(height),
    }, action_id);
  }
}

const actionBlock = function makeExistsActionBlock({ id, tid, type, constraints, title, errors, unload_id }) {
  const act = new Action(type, constraints, id ? id : tid);
  act.title = title;
  act.errors = errors;
  act.unloadId = unload_id;
  return act;
};

const validateAction = function validateAction(action, options = {}, hasSegmentTrash = null) {
  let queue_id = null;
  let bot_script_id = null;
  let ats_group_id = null;
  let last_status = null;
  let how_call = null;
  let steps1 = null;
  let options_field = null;
  let bpm_bp_id = null;
  let bpm_db_key = null;
  let promo_codes = null;
  let delay = null;
  let split0 = null;
  let sending_sms_value = null;
  let channels = null;
  let split_sum = 0;
  let event_signal_id = null;
  let event_signal_name = null;
  let custom_integration_id = null;

  switch (action.type) {
    case 'segment_trash':
    case 'segment':

      break;
    case 'bot_incall':
      bot_script_id = _.isInteger(Number(action.bot_script_id)) && Number(action.bot_script_id) > 0;
    // eslint-disable-next-line no-fallthrough
    case 'in':
      bpm_db_key = _.isString(options.bpm_db_key) && options.bpm_db_key.length > 0;
    // eslint-disable-next-line no-fallthrough
    case 'queue':
      if (_.isObject(action.queue)) {
        ats_group_id = _.isInteger(Number(action.queue.ats_group_id)) && Number(action.queue.ats_group_id) > 0;
        if (action.type === 'queue') {
          last_status = _.isInteger(Number(action.queue.last_status)) && Number(action.queue.last_status) > 0;
          how_call = !_.isNull(action.queue.how_call) && _.isInteger(Number(action.queue.how_call)) >= 0;
          try {
            steps1 = Boolean(_.size(JSON.parse(action.queue.steps1)));
          } catch (e) {
            steps1 = false;
          }
        }
      } else {
        queue_id = _.isInteger(Number(action.queue_id)) && Number(action.queue_id) > 0;
      }
      break;
    case 'callbot':
    case 'chatbot':
    case 'in_chatbot':
      bot_script_id = _.isInteger(Number(action.bot_script_id)) && Number(action.bot_script_id) > 0;
      if (_.isObject(action.queue)) {
        ats_group_id = _.isInteger(Number(action.queue.ats_group_id)) && Number(action.queue.ats_group_id) > 0;
        if (action.type === 'chatbot') {
          ats_group_id = null;
        } else if (action.type === 'callbot') {
          last_status = _.isInteger(Number(action.queue.last_status)) && Number(action.queue.last_status) > 0;
          how_call = !_.isNull(action.queue.how_call) && _.isInteger(Number(action.queue.how_call)) >= 0;
          try {
            steps1 = Boolean(_.size(JSON.parse(action.queue.steps1)));
          } catch (e) {
            steps1 = false;
          }
        }
      } else {
        queue_id = _.isInteger(Number(action.queue_id)) && Number(action.queue_id) > 0;
      }
      if (action.type === 'in_chatbot') {
        bpm_db_key = _.isString(options.bpm_db_key) && options.bpm_db_key.length > 0;
        promo_codes = _.isArray(action.promo_codes) && action.promo_codes.length > 0;
      }
      if (action.type === 'chatbot') {
        channels = options && _.isArray(options.channels) && options.channels.length > 0;
      }
      break;
    case 'transfer':
      bpm_bp_id = _.isInteger(Number(options.bpm_bp_id)) && Number(options.bpm_bp_id) > 0;
      delay = _.isInteger(Number(options.delay)) && Number(options.delay) >= 0 && options.delay !== '';
      break;
    case 'split':
      if (action.jumps.length > 0) {
        let numTrash = 0;
        // если есть действие Корзина, то исключаем из подсчета
        if (hasSegmentTrash) {
          numTrash = 1;
        }
        options_field = _.isArray(options) && options.length === (action.jumps.length - numTrash);
      }
      if (_.isArray(options)) {
        _.each(options, (opt) => split_sum += opt.percent);
      }
      split0 = split_sum === 100;
      break;
    case 'sms_template':
      sending_sms_value = Array.isArray(options.sending_sms_value) && options.sending_sms_value.length > 0;
      break;
    case 'event_finish':
      if(Number(options.sub_type) == 1){
        event_signal_id = _.isInteger(Number(options.event_signal_id)) && Number(options.event_signal_id) > 0;
      } else if (Number(options.sub_type) == 3) {
        event_signal_name = _.has(options, 'name') && Boolean(options.name) && /^[A-z0-9_-]+$/.test(options.name);
      }
      break;
    case 'custom_request':
      custom_integration_id = options
        && _.isInteger(Number(options.custom_integration_id))
        && Number(options.custom_integration_id) > 0
        && _.includes(['webhook', 'ftp'], options.integration_type);
      break;
  }

  const states = {
    title: _.isString(action.title) && action.title.length > 0 && action.title.length <= 256,
    type: _.isString(action.type) && actions.hasOwnProperty(action.type),
    queue_id,
    bot_script_id,
    ats_group_id,
    last_status,
    how_call,
    steps1,
    options: options_field,
    split0,
    bpm_bp_id,
    bpm_db_key,
    delay,
    sending_sms_value,
    event_signal_id,
    event_signal_name,
    promo_codes,
    channels,
    custom_integration_id,
    error: null,
  };

  for (let field in states) {
    if (states.hasOwnProperty(field) && states[field] === false) {
      states.error = field;
      return states;
    }
  }

  return states;
}

export {
  actionBlock,
  tid,
  validateAction,
};
