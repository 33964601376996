import api from './../../lib/api';
import VueRouter from "./../../routes.js";

const FTP_INTEGRATIONS_LOADING_CHANGE = 'FTP_INTEGRATIONS_LOADING_CHANGE';
const FTP_INTEGRATIONS_ERRORS_CHANGE = 'FTP_INTEGRATIONS_ERRORS_CHANGE';

export const FTP_INTEGRATIONS_LOAD = 'FTP_INTEGRATIONS_LOAD';
const FTP_INTEGRATIONS_LOAD_CHANGE = 'FTP_INTEGRATIONS_LOAD_CHANGE';

export const FTP_INTEGRATIONS_CREATE = 'FTP_INTEGRATIONS_CREATE';

export const FTP_INTEGRATIONS_UPDATE = 'FTP_INTEGRATIONS_UPDATE';
const FTP_INTEGRATIONS_CHANGE = 'FTP_INTEGRATIONS_UPDATE_CHANGE';

export const FTP_INTEGRATIONS_DELETE = 'FTP_INTEGRATIONS_DELETE';
const FTP_INTEGRATIONS_DELETE_CHANGE = 'FTP_INTEGRATIONS_DELETE_CHANGE';

export const FTP_INTEGRATIONS_RUN = 'FTP_INTEGRATIONS_RUN';
export const FTP_INTEGRATIONS_RUN_CHANGE = 'FTP_INTEGRATIONS_RUN_CHANGE';
const FTP_INTEGRATIONS_RUNNING = 'FTP_INTEGRATIONS_RUNNING';
const FTP_INTEGRATIONS_RUN_ERRORS_CHANGE = 'FTP_INTEGRATIONS_RUN_ERRORS_CHANGE';

const state = {
  ftp_integrations: [],
  ftp_integrations_loading: false,
  ftp_integrations_errors: null,
  test: {
    result: null,
    errors: null,
    running: false
  }
}

const actions = {
  [FTP_INTEGRATIONS_LOAD]({ commit, state }, data) {
    return api.list(
      'ftp_integrations',
      0,
      null,
      state,
      commit,
      FTP_INTEGRATIONS_LOADING_CHANGE,
      FTP_INTEGRATIONS_LOAD_CHANGE,
    );
  },
  [FTP_INTEGRATIONS_CREATE]({ commit, state }, data) {
    return api.create(
      'ftp_integrations.create',
      0,
      data,
      state,
      commit,
      FTP_INTEGRATIONS_LOADING_CHANGE,
      FTP_INTEGRATIONS_CHANGE,
      FTP_INTEGRATIONS_ERRORS_CHANGE
    );
  },
  [FTP_INTEGRATIONS_RUN]({ commit, state }, { id, params }) {
    return api.create(
      'ftp_integrations.run',
      id,
      params,
      state,
      commit,
      FTP_INTEGRATIONS_RUNNING,
      FTP_INTEGRATIONS_RUN_CHANGE,
      FTP_INTEGRATIONS_RUN_ERRORS_CHANGE
    );
  },
  [FTP_INTEGRATIONS_UPDATE]({ commit, state }, data) {
    return api.update(
      'ftp_integrations.update',
      data.id,
      data,
      state,
      commit,
      FTP_INTEGRATIONS_LOADING_CHANGE,
      FTP_INTEGRATIONS_CHANGE,
      FTP_INTEGRATIONS_ERRORS_CHANGE
    );
  },
  [FTP_INTEGRATIONS_DELETE]({ commit, state }, id) {
    return api.delete(
      'ftp_integrations.delete',
      id,
      state,
      commit,
      FTP_INTEGRATIONS_DELETE_CHANGE,
    );
  },
}

const mutations = {
  [FTP_INTEGRATIONS_LOADING_CHANGE](state, data) {
    state.ftp_integrations_loading = data;
  },
  [FTP_INTEGRATIONS_LOAD_CHANGE](state, data) {
    if (data?.data) {
      state.ftp_integrations = data.data;
    }
  },
  [FTP_INTEGRATIONS_CHANGE](state, data) {
    if (data?.data) {
      const _data = data.data;
      const exists = state.ftp_integrations.findIndex((event) => event.id === _data.id);
      if (exists >= 0) {
        state.ftp_integrations.splice(exists, 1);
      }
      state.ftp_integrations.push(_data);

      if (exists === -1) {
        VueRouter.push({
          name: 'custom-integration-event',
          params: { id: _data.id, event: _data.event, tabIndex: 1 },
        });
      }
    }
  },
  [FTP_INTEGRATIONS_DELETE_CHANGE](state, id) {
    if (id) {
      state.ftp_integrations = state.ftp_integrations.filter(item => item.id !== id);
      VueRouter.push({
        name: 'custom-integration'
      });
    }
  },
  [FTP_INTEGRATIONS_ERRORS_CHANGE](state, data) {
    state.ftp_integrations_errors = data;
  },

  [FTP_INTEGRATIONS_RUN_CHANGE](state, data) {
    state.test.result = data;
  },
  [FTP_INTEGRATIONS_RUNNING](state, data) {
    state.test.running = data;
  },
  [FTP_INTEGRATIONS_RUN_ERRORS_CHANGE](state, data) {
    state.test.errors = data;
  },
}

export default {
  state,
  actions,
  mutations
}