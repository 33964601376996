import Section from './Section';
import Screen from './Screen';
import Bot from './Bot';
import IntentClass from './Intent';
import {
  AccostAudio,
  AccostTts,
  AccostHss,
  AccostDtxt,
  AccostText,
  AccostTemplate,
  AccostImage,
  AccostVideo,
  AccostConditionAudio,
  IntentAny,
  IntentSample,
  IntentCustom,
  IntentFile,
  IntentPromoCode,
  ActionDone,
  ActionCallTransfer,
  ActionSendingSms,
  ActionStay,
  ActionHttpRequest,
  ActionFillOrder,
  ConditionOrderField,
  ConditionRepeat,
  ConditionTransitionIntents,
  fileCounts,
  fileTypes,
  ConditionTimeout,
  ConditionMessageStatus,
} from './blocks';

export default {
  createBlock(block, type) {
    let model;
    switch (`${block}.${type}`) {
      case 'bot.audio':
        model = new AccostAudio;
        break;
      case 'bot.tts':
        model = new AccostTts;
        break;
      case 'bot.hss':
        model = new AccostHss;
        break;
      case 'bot.dtxt':
        model = new AccostDtxt;
        break;
      case 'bot.text':
        model = new AccostText;
        break;
      case 'bot.img':
        model = new AccostImage;
        break;
      case 'bot.video':
        model = new AccostVideo;
        break;
      case 'bot.condition_audio':
        model = new AccostConditionAudio;
        break;
      case 'bot.wa_tpl':
        model = new AccostTemplate;
        break;
      case 'user.any':
        model = new IntentAny;
        break;
      case 'user.custom':
        model = new IntentCustom;
        break;
      case 'user.file':
        model = new IntentFile;
        break;
      case 'user.promo_code':
        model = new IntentPromoCode;
        break;
      case 'action.done':
        model = new ActionDone;
        break;
      case 'action.call_transfer':
        model = new ActionCallTransfer;
        break;
      case 'action.sending_sms':
        model = new ActionSendingSms;
        break;
      case 'action.stay':
        model = new ActionStay;
        break;
      case 'action.http_request':
        model = new ActionHttpRequest;
        break;
      case 'action.fill_order':
        model = new ActionFillOrder;
        break;
      case 'condition.order_field':
        model = new ConditionOrderField;
        break;
      case 'condition.repeat':
        model = new ConditionRepeat;
        break;
      case 'condition.transition_intents':
        model = new ConditionTransitionIntents;
        break;
      case 'condition.timeout':
        model = new ConditionTimeout;
        break;
      case 'condition.message_status':
        model = new ConditionMessageStatus;
        break;
    }
    return model;
  },
  createBot(data = null) {
    return new Bot(data);
  },
  createIntent(data = null) {
    return new IntentClass(data);
  },
  createSection(title = null) {
    if (title === null) {
      return new Section();
    } else {
      return new Section(title);
    }
  },
  createScreen(title = null) {
    if (title === null) {
      return new Screen();
    } else {
      return new Screen(title);
    }
  },
};

export {
  Bot,
  IntentClass,
  Section,
  Screen,
  AccostAudio,
  AccostTts,
  AccostText,
  AccostTemplate,
  AccostImage,
  AccostVideo,
  IntentAny,
  IntentSample,
  IntentCustom,
  IntentFile,
  IntentPromoCode,
  ActionDone,
  ActionCallTransfer,
  ActionSendingSms,
  ActionStay,
  ActionHttpRequest,
  ActionFillOrder,
  ConditionOrderField,
  ConditionRepeat,
  ConditionTransitionIntents,
  ConditionMessageStatus,
  ConditionTimeout,
  fileCounts,
  fileTypes,
};
