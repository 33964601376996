import Vue from 'vue';
import cronstrue from 'cronstrue/i18n';
import api from '@/lib/api';
import http from '@/lib/http';
import router from '@/routes.js';

const SCHEDULE_LIST_LOADED = 'SCHEDULE_LIST_LOADED';
const SCHEDULE_LIST_LOADING = 'SCHEDULE_LIST_LOADING';
const SCHEDULE_LOADED = 'SCHEDULE_LOADED';
const SCHEDULE_LOADING = 'SCHEDULE_LOADING';
const SCHEDULE_ATTACH_SCHEDULABLE = 'SCHEDULE_ATTACH_SCHEDULABLE';
const SCHEDULE_DETACH_SCHEDULABLE = 'SCHEDULE_DETACH_SCHEDULABLE';
const SCHEDULE_CREATED = 'SCHEDULE_CREATED';
const SCHEDULE_CREATED_ERRORS = 'SCHEDULE_CREATED_ERRORS';
const SCHEDULE_DELETED = 'SCHEDULE_DELETED';
const SCHEDULE_DELETED_ERRORS = 'SCHEDULE_DELETED_ERRORS';
const state = {
  errors: {},
  reload: false,
  schedules: [],
  pagination: {
    page: 1,
    per_page: 15,
    total: 0,
  },
  schedule: null,
  loading: false,
  listLoading: false,
  newSchedule: () => ({
    id: 'new',
    title: cronstrue.toString('0 9 * * 1-5', { locale: 'en' }),
    cron_expression: '0 9 * * 1-5',
    end_date: null,
    max_runs: 1,
    run_count: 0,
    active: false,
    campaigns: [],
    integrations: [],
  }),
};

const getters = {
  schedule: (state) => (id) => {
    return state.schedules.find((schedule) => schedule.id === id);
  },
  scheduleDetailed: (state) => (id) => {
    return state.schedule?.id === id ? state.schedule : null;
  },
};

const actions = {
  getSchedules({ commit }, params = {}) {
    return api.list(
      'scheduler.schedules',
      0,
      {
        page: params.page || state.pagination.page,
        per_page: state.pagination.per_page,
        hide_disabled: params.hideDisabled,
      },
      state,
      commit,
      SCHEDULE_LIST_LOADING,
      SCHEDULE_LIST_LOADED,
    );
  },
  getSchedule({ commit, state }, id) {
    if (!id ||id === 'new') {
      commit(SCHEDULE_LOADED, {
        data: state.newSchedule(),
      });
      return Promise.resolve(true);
    }
    return api.load('scheduler.schedule', id, state, commit, SCHEDULE_LOADING, SCHEDULE_LOADED);
  },
  attachSchedulable({ commit, state }, { id, data }) {
    const url = api.format('scheduler.schedule.attach_schedulable', id);
    return http.post(url, data).then((response) => {
      commit(SCHEDULE_ATTACH_SCHEDULABLE, response);
    });
  },
  detachSchedulable({ commit, state }, { id, data }) {
    const url = api.format('scheduler.schedule.detach_schedulable', id);
    return http.delete(url, data).then((response) => {
      commit(SCHEDULE_DETACH_SCHEDULABLE, response);
    });
  },
  createSchedule({ commit }, data) {
    commit(SCHEDULE_LOADING, true);

    const url = api.format('scheduler.schedules');

    return http.post(url, data).then(({ data }) => {
      commit(SCHEDULE_LOADING, false);
      commit('needReload');
      return data?.data?.id;
    }).catch((error) => {
      commit(SCHEDULE_CREATED_ERRORS, error);
      commit(SCHEDULE_LOADING, false);
      return Promise.reject(error);
    });
  },
  saveSchedule({ commit }, data) {
    commit(SCHEDULE_LOADING, true);

    const url = api.format('scheduler.schedule', data.id);

    return http.put(url, data).then(({ data }) => {
      commit(SCHEDULE_LOADING, false);
      commit('needReload');
      return data?.data?.id;
    })
    .catch((error) => {
      commit(SCHEDULE_CREATED_ERRORS, error);
      commit(SCHEDULE_LOADING, false);
      return Promise.reject(error);
    });
  },
  deleteSchedule({ commit }, id) {
    const url = api.format('scheduler.schedule', id);
    return http.delete(url).then(({ data }) => {
      commit(SCHEDULE_DELETED, id);
      return data;
    }).catch((error) => {
      commit(SCHEDULE_DELETED_ERRORS, error);
      return false;
    });
  },
};

const mutations = {
  [SCHEDULE_LOADING](state, loading) {
    state.loading = loading;
  },
  [SCHEDULE_LIST_LOADING](state, loading) {
    state.listLoading = loading;
  },
  [SCHEDULE_LIST_LOADED](state, response) {
    state.reload = false;
    if (response && !_.isEmpty(response.data)) {
      state.schedules = response.data;
      state.pagination.total = response.total;
      if (response.last_page < state.pagination.page) {
        state.pagination.page = response.last_page;
      }
    } else {
      state.schedules = [];
    }
  },
  [SCHEDULE_LOADED](state, response) {
    if (response && !_.isEmpty(response.data)) {
      state.schedule = response.data;
    } else {
      state.schedule = null;
    }
  },
  [SCHEDULE_CREATED](state, response) {
    if (response && !_.isEmpty(response.data) && response.data.id) {
      state.schedule = response.data;
      router.push({ name: 'schedule-edit', params: { id: response.data.id } });
    } else {
      state.schedule = null;
    }
  },
  [SCHEDULE_CREATED_ERRORS](state, response) {
    if (response) {
      if (!_.isEmpty(response.errors)) {
        state.errors = response.errors;
      }

      if (response.message) {
        Vue.notify({
          group: 'app',
          type: 'error',
          title: response.message,
          text: response.errors,
        });
      }
    } else {
      state.errors = {};
    }
  },
  [SCHEDULE_ATTACH_SCHEDULABLE](state, response) {
    if (response && !_.isEmpty(response.data)) {
      console.log(SCHEDULE_ATTACH_SCHEDULABLE, response.data);
      if (response.data.success) {
        Vue.notify({
          group: 'app',
          type: 'success',
          title: 'Success',
          text: 'Schedule successfully attached',
        });
      } else {
        Vue.notify({
          group: 'app',
          type: 'error',
          title: response.data.message,
          text: response.data.errors?.join('\n'),
        });
      }
    }
  },
  [SCHEDULE_DETACH_SCHEDULABLE](state, response) {
    if (response && !_.isEmpty(response.data)) {
      if (response.data.success) {
        Vue.notify({
          group: 'app',
          type: 'success',
          title: 'Success',
          text: 'Schedule successfully detached',
        });
      } else {
        Vue.notify({
          group: 'app',
          type: 'error',
          title: response.data.message,
          text: response.data.errors?.join('\n'),
        });
      }
    }
  },
  [SCHEDULE_DELETED](state, id) {
    state.schedules = state.schedules.filter((schedule) => schedule.id !== id);
  },
  [SCHEDULE_DELETED_ERRORS](state, response) {
    Vue.notify({
      group: 'app',
      type: 'error',
      title: response.message,
      text: response.errors || response.exception,
    });
  },
  needReload(state) {
    state.reload = true;
  },
  reloaded(state) {
    state.reload = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
