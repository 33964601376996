import Vuex from "vuex";
import Vue from "vue";

import ats_monitoring from "./modules/ats_monitoring";
import ats_queues from "./modules/ats_queues";
import out_routes from "./modules/out_routes";
import caller_ids from "./modules/caller_ids";
import sips from "./modules/sips";
import providers from "./modules/providers";
import ats from "./modules/ats";
import ats_groups from "./modules/ats_groups";
import ats_users from "./modules/ats_users";
import orders from "./modules/orders";
import organizations from "./modules/organizations";
import users from "./modules/users";
import products from "./modules/products";
import statuses from "./modules/statuses";
import delivery_types from "./modules/delivery_types";
import projects from "./modules/projects";
import project_goal_script from "./modules/project_goal_script";
import permissions from "./modules/permissions";
import unload from "./modules/unload";
import geos from "./modules/geos";
import calls from "./modules/calls";
import project_category from "./modules/project_category";
import traffics from "./modules/traffics";
import product_category from "./modules/product_category";
import call_center from "./modules/call_center";
import currency from "./modules/currency";
import entities from "./modules/entities";
import entity_params from "./modules/entity_params";
import role_groups from "./modules/role_groups";
import roles from "./modules/roles";
import black_list from "./modules/black_list";
import sms_providers from "./modules/sms_providers";
import accord from "./modules/accord";
import sms_rules from "./modules/sms_rules";
import sms from "./modules/sms";
import get_address from "./modules/get_address";
import filters from "./modules/filters";
import dx_tables from "./modules/dx_tables";
import log_activities from "./modules/log_activities";
import order_senders from "./modules/order_senders"
import profile from "./modules/profile"
import integrations from "./modules/integrations"
import ftp_integrations from "./modules/ftp_integrations"
import sms_templates from "./modules/sms_templates";
import order_advert_sources from "./modules/order_advert_sources";
import copy from "./modules/copy";
import order_validators from "./modules/order_validators";
import pimpay_invoices from "./modules/pimpay_invoices";
import owners from "./modules/owners";
import wholesaler_services from "./modules/wholesaler_services";
import wholesaler_stocks from "./modules/wholesaler_stocks"
import wholesaler_orders from "./modules/wholesaler_orders"
import wholesaler_purchases from "./modules/wholesaler_purchases";
import wholesaler_unload from "./modules/wholesaler_unload";
import wholesaler_clients from "./modules/wholesaler_clients";
import opt_traffic_sources from "./modules/opt_traffic_sources";
import sale_statuses from "./modules/sale_statuses";
import ats_queue_actions from "./modules/ats_queue_actions";
import page_settings from "./modules/page_settings";
import competence_cats from "./modules/competence_cats";
import otsledi from "./modules/otsledi";
import quantity_products from "./modules/quantity_products";
import fine_cats from "./modules/fine_cats";
import reason_fines from "./modules/reason_fines";
import test_questions from "./modules/test_questions";
import tests from "./modules/tests";
import competences from "./modules/competences";
import user_forms from "./modules/user_forms";
import fines from "./modules/fines";
import audit_orders from "./modules/audit_orders";
import payment_types from "./modules/payment_types";
import packed from "./modules/packed";
import packed_standards from "./modules/packed_standards";
import report_settings from "./modules/report_settings";
import delivery_dpd from "./modules/delivery_dpd";
import telefony_check from "./modules/telefony_check";
import sms_check from "./modules/sms_check";
import sales_report from "./modules/sales_report";
import sales_section_report from "./modules/sales_section_report";
import hp_base from "./modules/hp_base";
import sip_srm_properties from "./modules/sip_srm_properties";
import robot_steps from "./modules/robot_steps";
import interpretations from "./modules/interpretations";
import points_with_grades from "./modules/points_with_grades";
import grade_options from "./modules/grade_options";
import question_groups from "./modules/question_groups";
import robot from "./modules/robot";
import waba_templates from "./modules/waba_templates";
import my_targets from "./modules/my_targets";
import notification from "./modules/notification";
import bpm from "./modules/bpm";
import hash_tab from "./modules/hash_tab";
import industries from "./modules/industries";
import decision_templates from "./modules/decision_templates";
import tariffs from "./modules/tariffs";
import amocrm from "./modules/amocrm";
import bitrix from "./modules/bitrix";
import kassa_com from "./modules/kassa_com";
import tariff_messengers from "./modules/tariff_messengers";
import tariffs_sms from "./modules/tariffs_sms";
import tariffs_speech from "./modules/tariffs_speech";
import chat_bot_setups from "./modules/chat_bot_setups";
import messenger_accounts from "./modules/messenger_accounts";
import telephony_numbers from "./modules/telephony_numbers";
import bot_campaigns from "./modules/bot_campaigns";
import scheduler from "./modules/scheduler";
import rb_reports from "./modules/rb_reports";
import rb_dashboards from "./modules/rb_dashboards";
import rb_constructor_reports from "./modules/rb_constructor_reports";
import rb_constructor_templates from "./modules/rb_constructor_templates";
import rb_constructor_sources from "./modules/rb_constructor_sources";
import rb_constructor_columns from "./modules/rb_constructor_columns";
import rb_constructor_dashboards from "./modules/rb_constructor_dashboards";
import bpm_cases from "./modules/bpm_cases";
import tags from "./modules/tags";
import bpm_action_operator_scripts from "./modules/bpm_action_operator_scripts";
import solutions_draft from "./modules/solutions_draft";
import robot_projects from "./modules/robot_projects";
import nlu from "./modules/nlu";
import nlu_groups from "./modules/nlu_groups";
import nlu_servers from "./modules/nlu_servers";
import speech from "./modules/speech";
import phrases from "./modules/phrases";
import moderations from "./modules/moderations";
import used_phone_numbers from "./modules/used_phone_numbers";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    ats_queue_actions,
    page_settings,
    accord,
    order_validators,
    ats_monitoring,
    ats_queues,
    out_routes,
    caller_ids,
    sips,
    providers,
    ats,
    ats_groups,
    robot_steps,
    ats_users,
    orders,
    organizations,
    users,
    products,
    statuses,
    delivery_types,
    projects,
    project_goal_script,
    permissions,
    unload,
    geos,
    calls,
    log_activities,
    project_category,
    traffics,
    call_center,
    currency,
    entities,
    entity_params,
    role_groups,
    roles,
    black_list,
    sms_providers,
    sms_rules,
    sms,
    get_address,
    filters,
    dx_tables,
    product_category,
    order_senders,
    profile,
    integrations,
    ftp_integrations,
    sms_templates,
    order_advert_sources,
    copy,
    pimpay_invoices,
    owners,
    competence_cats,
    competences,
    wholesaler_services,
    wholesaler_stocks,
    wholesaler_orders,
    wholesaler_purchases,
    wholesaler_unload,
    wholesaler_clients,
    opt_traffic_sources,
    sale_statuses,
    otsledi,
    quantity_products,
    fine_cats,
    reason_fines,
    test_questions,
    tests,
    user_forms,
    fines,
    audit_orders,
    payment_types,
    packed,
    report_settings,
    packed_standards,
    delivery_dpd,
    telefony_check,
    sms_check,
    sales_report,
    sales_section_report,
    hp_base,
    sip_srm_properties,
    interpretations,
    question_groups,
    robot,
    waba_templates,
    points_with_grades,
    grade_options,
    my_targets,
    notification,
    bpm,
    hash_tab,
    tariffs,
    tariff_messengers,
    tariffs_sms,
    tariffs_speech,
    chat_bot_setups,
    industries,
    messenger_accounts,
    telephony_numbers,
    decision_templates,
    bot_campaigns,
    scheduler,
    amocrm,
    bitrix,
    kassa_com,
    rb_reports,
    rb_dashboards,
    rb_constructor_reports,
    rb_constructor_templates,
    rb_constructor_sources,
    rb_constructor_columns,
    rb_constructor_dashboards,
    bpm_cases,
    tags,
    robot_projects,
    nlu,
    nlu_groups,
    nlu_servers,
    speech,
    bpm_action_operator_scripts,
    solutions_draft,
    phrases,
    moderations,
    used_phone_numbers,
  }
});
