import http from './../../lib/http';
import api from './../../lib/api';

export const SMS_TEMPLATES_LIST_LOAD         = "SMS_TEMPLATES_LIST_LOAD";
export const SELECT_SMS_TEMPLATES_LIST_LOAD  = "SELECT_SMS_TEMPLATES_LIST_LOAD";
export const SELECT_SMS_TEMPLATES_LIST_SEARCH  = "SELECT_SMS_TEMPLATES_LIST_SEARCH";
export const SMS_TEMPLATE_LOAD               = "SMS_TEMPLATES_LOAD";
export const SMS_TEMPLATE_CREATE             = "SMS_TEMPLATES_CREATE";
export const SMS_TEMPLATE_UPDATE             = "SMS_TEMPLATES_UPDATE";
export const SMS_TEMPLATES_ORDER_LOAD        = "SMS_TEMPLATES_ORDER_LOAD";

const SMS_TEMPLATES_LIST_CHANGE              = "SMS_TEMPLATES_LIST_CHANGE";
const SMS_TEMPLATES_LIST_LOADING_CHANGE      = "SMS_TEMPLATES_LIST_LOADING_CHANGE";

const SELECT_SMS_TEMPLATES_LIST_CHANGE         = "SELECT_SMS_TEMPLATES_LIST_CHANGE";
const SELECT_SMS_TEMPLATES_LIST_LOADING_CHANGE = "SELECT_SMS_TEMPLATES_LIST_LOADING_CHANGE";

const SMS_TEMPLATE_CHANGE                    = "SMS_TEMPLATE_CHANGE";
const SMS_TEMPLATE_LOADING_CHANGE            = "SMS_TEMPLATE_LOADING_CHANGE";
const SMS_TEMPLATE_ERRORS_CHANGE             = "SMS_TEMPLATE_ERRORS_CHANGE";
const SMS_TEMPLATE_SAVING_CHANGE             = "SMS_TEMPLATE_SAVING_CHANGE";

const SMS_TEMPLATE_CREATING_CHANGE           = "SMS_TEMPLATE_CREATING_CHANGE";
const SMS_TEMPLATE_CREATING_ERRORS_CHANGE    = "SMS_TEMPLATE_CREATING_ERRORS_CHANGE";

const state = {
    sms_template: null,
    sms_template_loading: false,
    sms_template_saving: false,
    sms_template_errors: {},

    sms_template_list: null,
    sms_template_list_loading: false,

    sms_templates_list: null,
    select_sms_template_list_loading: false,

    sms_template_creating: false,
    sms_template_creating_errors: {},
    sms_template_empty: {
        id: 0,
        name: "",
        sms_text: "",
        is_work: false,
        organization_id: [],
        options: {
            is_shorten_link: false,
            default_redirect_url: '',
            redirect_delay: 1,
            days_until_expiration: 90,
            domains: [],
            signal: null,
        },
    }
};

const actions = {
    [SMS_TEMPLATES_LIST_LOAD]({commit, state}, data) {
        let id = data && data.id ? data.id : 0;
        let params = data && data.params ? data.params : null;

        return api.list(
            'sms_templates',
            id,
            params,
            state,
            commit,
            SMS_TEMPLATES_LIST_LOADING_CHANGE,
            SMS_TEMPLATES_LIST_CHANGE,
        );
    },

    [SELECT_SMS_TEMPLATES_LIST_LOAD]({commit, state}, data) {
        let id = data && data.id ? data.id : 0;
        let params = data && data.params ? data.params : null;

        return api.list(
            'select_sms_templates_list',
            id,
            params,
            state,
            commit,
            SELECT_SMS_TEMPLATES_LIST_LOADING_CHANGE,
            SELECT_SMS_TEMPLATES_LIST_CHANGE,
        );
    },

    [SELECT_SMS_TEMPLATES_LIST_SEARCH]({commit, state}, data) {
        let id = data && data.id ? data.id : 0;
        let params = data ? data : null;

        return api.list(
            'select_sms_templates_list_search',
            id,
            params,
            state,
            commit,
            SELECT_SMS_TEMPLATES_LIST_LOADING_CHANGE,
            SELECT_SMS_TEMPLATES_LIST_CHANGE,
        );
    },

    [SMS_TEMPLATES_ORDER_LOAD]({commit, state}, id) {

        return api.load(
            'sms_templates_order',
            id,
            state,
            commit,
            SMS_TEMPLATES_LIST_LOADING_CHANGE,
            SMS_TEMPLATES_LIST_CHANGE,
        );
    },

    [SMS_TEMPLATE_LOAD]({state, commit}, id) {
        if (!id) {
            commit(SMS_TEMPLATE_CHANGE, null);
            return
        }

        return api.load(
            'sms_template',
            id,
            state,
            commit,
            SMS_TEMPLATE_LOADING_CHANGE,
            SMS_TEMPLATE_CHANGE,
        );
    },
    [SMS_TEMPLATE_CREATE]({state, commit}, data) {
        let id = data.id ? data.id : 0;
        let model = data.model;

        if (_.has(model.options, 'cut_https') && !model.options.cut_https) {
            delete model.options.cut_https;
        }

        if (model.options.is_shorten_link === false) {
            model.options = _.pick(model.options, ['is_shorten_link', 'cut_https']);
        }

        return api.create(
            'sms_templates',
            id,
            model,
            state,
            commit,
            SMS_TEMPLATE_CREATING_CHANGE,
            SMS_TEMPLATE_CHANGE,
            SMS_TEMPLATE_CREATING_ERRORS_CHANGE,
        );
    },
    [SMS_TEMPLATE_UPDATE]({state, commit}, data) {
        let id = data.id;
        let model = data.model;

        if (_.has(model.options, 'cut_https') && !model.options.cut_https) {
            delete model.options.cut_https;
        }

        if (model.options.is_shorten_link === false) {
            model.options = _.pick(model.options, ['is_shorten_link', 'cut_https']);
        }

        return api.update(
            'sms_template',
            id,
            model,
            state,
            commit,
            SMS_TEMPLATE_SAVING_CHANGE,
            SMS_TEMPLATE_CHANGE,
            SMS_TEMPLATE_ERRORS_CHANGE,
        );
    },
};

const mutations = {
    [SMS_TEMPLATES_LIST_CHANGE](state, data) {
        if (data) {
            state.sms_template_list = data.data ? data.data : data;
        }
    },
    [SMS_TEMPLATES_LIST_LOADING_CHANGE](state, data) {
        state.sms_template_list_loading = data;
    },
    [SELECT_SMS_TEMPLATES_LIST_CHANGE](state, data) {
        if (data) {
            state.sms_templates_list = data.data ? data.data : data;
        }
    },
    [SELECT_SMS_TEMPLATES_LIST_LOADING_CHANGE](state, data) {
        state.select_sms_template_list_loading = data;
    },
    [SMS_TEMPLATE_CHANGE](state, data) {
        state.sms_template = _.cloneDeep(state.sms_template_empty);

        if (data) {
            state.sms_template = _.merge({}, state.sms_template, data)
        }
    },
    [SMS_TEMPLATE_LOADING_CHANGE](state, data) {
        state.sms_template_loading = data;
    },
    [SMS_TEMPLATE_ERRORS_CHANGE](state, data) {
        state.sms_template_errors = data;
    },
    [SMS_TEMPLATE_SAVING_CHANGE](state, data) {
        state.sms_template_saving = data;
    },
    [SMS_TEMPLATE_CREATING_CHANGE](state, data) {
        state.sms_template_creating = data;
    },
    [SMS_TEMPLATE_CREATING_ERRORS_CHANGE](state, data) {
        state.sms_template_creating_errors = data;
    },
};

export default {
    state,
    actions,
    mutations,
}
